




























































import {Component, Ref, Vue} from "vue-property-decorator"
import Project from "@/models/Project";
import Marked from 'marked'
import ProjectService from "@/services/ProjectService";

@Component
export default class ProjectView extends Vue {
    loading: boolean = false
    project: Project = new Project()
    @Ref() readonly markedId!: HTMLParagraphElement


    created() {
        ProjectService.getProject(this, parseInt(this.$route.params.id))
            .then(() => {
            this.markedId.innerHTML = Marked(this.project.body!)
        })

    }

    mounted() {
        this.markedId.innerHTML = Marked(this.project.body!) + "prueba"
    }

    // date(createdAt: string) {
    //     return format(new Date(createdAt), "dd $ MMMM, YYY", {locale: es})
    // }

    goingTo(dir : string) {
        window.open(dir, "_blank")
    }

}
